@import "Variables";

.muiModal {
  height: 100vh;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(#000000, 0.2);
  z-index: 10000001 !important;

  .datePicker {
    width: 100% !important;
    border-color: lightgray !important;
    border: 2px solid;
    border-radius: 4px;
    padding-top: 10px !important;
    padding-left: 15px !important;
    background: white !important;
  }

  .datePicker:focus {
    border-color: #4a9876 !important;
  }

  .datePicker label:focus {
    color: "red";
  }

  .error-placeholder::placeholder {
    color: #d32f2f !important;
  }

  .react-datepicker-wrapper {
    width: 94% !important;
    background: white !important;
  }

  .timePicker {
    width: 98% !important;
    border-color: lightgray !important;
    border: 1px solid;
    border-radius: 4px;
    padding: 8px !important;
    background: white !important;
  }
  .timePickerErr {
    width: 98% !important;
    border-color: #d32f2f !important;
    color: "#d32f2f" !important;
    border: 1px solid;
    border-radius: 4px;
    padding: 8px !important;
    background: white !important;
  }

  .timePickerErr::placeholder {
    color: #d32f2f !important;
  }

  .timePicker:focus {
    border-color: #4a9876 !important;
    border: 2px solid;
  }
}

.muiButtons {
  display: flex;
  position: absolute;
  bottom: 24px;
  width: 100%;
}

.muiModal input {
  font-size: 14px !important;
}
.muiModal label {
  font-size: 14px !important;
}
.muiModal select {
  font-size: 14px !important;
}

.homeBase {
  input {
    width: 98% !important;
    border-color: #c4c4c4 !important;
    border: 1px solid;
    border-radius: 4px;
    padding-top: 10px !important;
    padding-left: 15px !important;
    background: white !important;
  }

  input:focus {
    border-color: #4a9876 !important;
    border: 2px solid;
  }
}

.fieldSizing {
  input {
    padding-bottom: 11px !important;
  }
}

.controlsForms {
  text-align: left;
  position: relative;
  display: inline-block;
  .select__menu {
    z-index: 10000004 !important;
  }
  .select__control {
    &:focus {
      border-color: #4a9876;
      outline: #4a9876;
    }
  }
  input[type="text"],
  select {
    padding: 8px 4px;
    font-size: 15px;
    width: 100%;
    font-weight: 400;
    background-color: #fff;
    height: 18px;
    &:focus,
    &:hover {
      outline: none;
      + label {
        cursor: text;
      }
    }
  }
  select {
    cursor: pointer;
  }
  label {
    position: absolute;
    left: 8px;
    top: 12px;
    color: #999;
    font-size: 14px;
    display: inline-block;
    padding: 4px 10px;
    font-weight: 400;
    pointer-events: none;
    &.activeLabel {
      font-weight: 500;
      top: -11px;
      color: #555;
      background-color: rgba(255, 255, 255, 1);
    }
  }
}
.modal {
  height: 100vh;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(#000000, 0.2);
  z-index: 10000000;

  // opacity: 0;
  // visibility: hidden;
  p {
    font-size: 14px;
  }
  &-searchable-contact-div {
    width: 100%;
    div {
      display: block !important;
    }
  }
  &__helper {
    background-color: white;
    position: absolute;
    width: 250px;
    height: 17vh;
    right: 4%;
    top: 5%;
    border: 1px solid black;
    padding: 15px 30px;
    border-radius: 10px;
    z-index: 10000000;
    font-size: 15px;
    line-height: 19px;

    @media only screen and (max-width: 1270px) {
      width: 200px;
      height: 22vh;
    }
    @media only screen and (max-width: 1180px) {
      display: none;
    }
  }

  &__content {
    background-color: white;
    position: relative;
    width: 24vw;
    height: 85vh;
    left: 35%;
    top: 5%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 15px 30px;
    padding-top: 24px;
    border-radius: 10px;
    z-index: 10000000;
    @media only screen and (max-width: 800px) {
      left: 0%;
      top: 0%;
      width: 86vw;
      height: 89vh;
    }
    @media only screen and (max-width: 500px) {
      padding: 10px 20px;
      left: 0%;
      top: 0%;
      width: 90.2vw;
      height: 89vh;
    }
  }
  &__form {
    padding: 15px;
    max-height: 66vh;
    overflow-y: auto;
    overflow-x: hidden;
    @media only screen and (max-width: 500px) {
      max-height: 70vh;
    }
  }

  &__info {
    color: white;
    background-color: #fdd14f;
    padding: 15px 21px;
    border-radius: 50%;
    margin-right: 10px;
    position: absolute;
    top: 3%;
  }

  &__home {
    color: white;
    background-color: #fdd14f;
    padding: 15px 15px;
    border-radius: 50%;
    margin-right: 10px;
    position: absolute;
    top: 3%;
  }

  &__check {
    color: white;
    background-color: #20da8d;
    padding: 15px 15px;
    border-radius: 50%;
    margin-right: 10px;
    position: absolute;
    top: 3%;
  }

  &__head {
    display: inline-block;
    margin-left: 15px;
  }

  &__header {
    margin-bottom: 10px;
    margin-top: 3px;
  }

  &__heading {
    font-weight: 600;
    margin: 0px auto;
    font-size: 20px;

    &-desc {
      margin: 2px auto;
      font-weight: 300;
      font-size: 14px;
    }

    &-sub {
      font-weight: 500;
      margin: 10px auto 10px auto;
      font-size: 14px;
    }
  }

  &__close {
    position: absolute;
    top: 3%;
    right: 5%;

    &:hover {
      color: #d3d3d3;
    }
  }

  &__restore {
    position: absolute;
    top: 3%;
    right: 5%;

    &:hover {
      color: #61c99d;
    }
  }

  &__address {
    display: flex;
    &-state {
      margin-right: 4px !important;
      width: 90px !important;
    }
    &-zip {
      width: 100px !important;
      margin-right: 4px !important;
    }
    &-city {
      width: 90% !important;
    }
  }

  &__addMethod {
    border: 1px dashed #c5c5d3;
    background-color: white;
    padding: 7px;
    cursor: pointer;
    margin-left: 15px;
    border-radius: 5px;
  }

  &__buttons {
    display: flex;
    position: absolute;
    bottom: 2.3vh;
    width: 100%;

    @media only screen and (max-width: 400px) {
      bottom: 5%;
      // position: static;
    }
    @media only screen and (max-width: 400px) and (max-height: 600px) {
      // position: static !important;
      bottom: 3.5% !important;
    }
  }

  &__addButton {
    color: white;
    background: linear-gradient(0deg, #61c99d, #61c99d);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    position: absolute;
    bottom: 1px;
    right: 15%;
    padding: 2px 17px;
    width: 80.22px;
    height: 35px;
    z-index: 1000;
    display: block;

    @media only screen and (max-width: 780px) {
      left: 20%;
    }
    @media only screen and (max-width: 600px) {
      left: 30%;
    }

    &-signature {
      position: absolute;
      bottom: 0px;
      right: 25%;
      padding: 2px 10px;
      border: 1px solid #dadada;
      background: white;
      width: auto;
      height: 35px;
      @media only screen and (max-width: 780px) {
        right: 50%;
        left: auto;
      }
      @media only screen and (max-width: 600px) {
        display: none;
      }
    }
    &-email-template {
      position: absolute;
      bottom: 0px;
      right: 114px;
      padding: 1.25px 10px;
      // border: 1px solid #dadada;
      background: #f9fafb;
      width: auto;
      height: 28px;
      z-index: 0;
      outline: none;
      &:hover {
        border: 1px solid #212123;
      }
      @media only screen and (max-width: 780px) {
        right: 15%;
        left: auto;
      }
      // @media only screen and (max-width: 600px) {
      //   display: none;
      // }
    }
    &-email-sequence {
      position: absolute;
      bottom: 0px;
      right: 208px;
      padding: 1.25px 10px;
      // border: 1px solid #dadada;
      background: transparent;
      width: auto;
      height: 28px;
      z-index: 0;
      outline: none;
      color: #000000;
      &:hover {
        border: 1px solid #212123;
      }
      @media only screen and (max-width: 780px) {
        right: 30%;
        left: auto;
      }
      // @media only screen and (max-width: 600px) {
      //   display: none;
      // }
    }
    &-email-schedule {
      z-index: 100;
      outline: none;
      background: #212123;
      width: auto;
      height: 35px;
      border-left: 1px solid #fff;
      border-radius: 0px 4px 4px 0px;
      color: white !important;
      font-size: 16px;
      text-align: center;
      align-items: center;
      vertical-align: middle;
      color: $color-green-800;
    }
    &-email-personalize {
      position: absolute;
      bottom: -3px;
      right: 114px;
      padding: 2px 10px;
      // border: 1px solid #dadada;
      z-index: 0;
      outline: none;
      background: transparent;
      width: auto;
      height: 33px;
      // &:hover {
      //   border: 1px solid #212123;
      // }
      @media only screen and (max-width: 780px) {
        right: 0%;
        left: auto;
      }
    }
  }
  &__addButton-1 {
    color: white;
    background-color: #005ce5;
    padding: 7px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
  }

  &__clear {
    border: 1px solid #e0ebe6;
    color: #252a2d;
    background-color: white;
    margin-right: 30px;
    cursor: pointer;
    border-radius: 4px;
    outline: none;
    width: 80.22px;
    font-weight: 600;
    height: 35px;
    position: absolute;
    bottom: 1px;
    right: 30%;
    padding: 2px 17px;
    z-index: 1000;
    display: block;

    @media only screen and (max-width: 780px) {
      left: 0%;
    }
  }
  &__clear-1 {
    border: 1px solid #e0ebe6;
    color: #252a2d;
    background-color: white;
    margin-right: 30px;
    padding: 7px;
    cursor: pointer;
    border-radius: 4px;
    outline: none;
    width: 80.22px;
    font-weight: 600;
    height: 35px;
  }

  &__hint {
    color: #c5c5d3;
    font-size: 12px;
  }

  &:target {
    opacity: 1;
    visibility: visible;
  }

  &:target &__content {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    transition: all 0.4s 0.2s;
  }
}

.saveBtn {
  color: white;
  background: #252a2d;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  position: absolute;
  bottom: 0px;
  right: 15%;
  padding: 2px 17px;
  width: auto;
  height: 35px;
  z-index: 1000;
  display: block;

  &:hover {
    background-color: #e3e3e3 !important;
    color: #000 !important;
    border: 1px solid black;

    svg {
      color: #000 !important;
      stroke: #000 !important;
    }
  }
}

.modalEmail {
  height: 100vh;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  // background-color: rgba(#F3F4F6, 0.2);
  background-color: rgba(#000, 0.2);
  // background-color: transparent;
  z-index: 10000000;
}

.modal input,
label {
  display: block;
}

.modal label {
  font-size: 12px;
}

.modal input {
  border: 1px solid #c5c5d3;
  border-radius: 4px;
  padding: 5px;
  width: 95%;
  margin: 5px 0;
}
.modal-datepicker {
  width: 100% !important;
}

.modal textarea {
  border: 1px solid #c5c5d3;
  border-radius: 4px;
  padding: 5px;
  width: 95%;
  margin: 5px 0;
}

.modal select {
  border: 1px solid #c5c5d3;
  border-radius: 4px;
  margin: 5px 0;
  width: 100px;
  padding: 3px;
}

.modal-radioButton {
  display: flex;

  button {
    margin-right: 20px;
    border: 1px solid black;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .radio-button {
    display: none;
  }
}

.opportunity__content {
  background-color: white;
  position: relative;
  width: 400px;
  height: 91vh;
  // left: 500.47px;
  left: 35%;
  top: 3%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 15px 30px;
  border-radius: 10px;
  @media only screen and (max-width: 800px) {
    left: 0%;
    top: 0%;
    width: 86vw;
    height: 89vh;
  }
}

.contact-existing-button {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  color: #4a9876;
  border: none;
  background: transparent;
  outline: none;
  position: absolute;
  bottom: 1px;
  text-decoration: underline;
  cursor: pointer;

  @media only screen and (max-width: 780px) {
    right: 22px;
  }
}

.modal__confirm-button {
  color: white;
  background: #212123;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  position: absolute;
  bottom: 1px;
  right: 15%;
  padding: 10px;
  // width: 80.22px;
  // height: 26px;
  z-index: 10000002;
  display: block;

  @media only screen and (max-width: 780px) {
    left: 20%;
  }
  @media only screen and (max-width: 600px) {
    left: 30%;
  }
  @media only screen and (max-width: 400px) {
    bottom: 5%;
    left: 40%;
    right: 7%;
  }
  @media only screen and (max-width: 400px) and (max-height: 600px) {
    bottom: 3.5% !important;
  }

  &:hover {
    background-color: #e3e3e3 !important;
    color: black;
    border: 1px solid black;

    svg {
      color: #000 !important;
      stroke: #000 !important;
    }
  }
}

.modal__cancel-button {
  border: none;
  color: #212123;
  background-color: white;
  margin-right: 30px;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
  // width: 80.22px;
  font-weight: 600;
  // height: 35px;
  position: absolute;
  bottom: 1px;
  left: 0%;
  padding: 5px 12px;
  //z-index: 10000002;
  display: block;

  &:hover {
    background: #f1f2f4;
  }

  @media only screen and (max-width: 780px) {
    left: 0%;
  }
  @media only screen and (max-width: 400px) {
    bottom: 5%;
    left: 5%;
  }
  @media only screen and (max-width: 400px) and (max-height: 600px) {
    bottom: 3.5% !important;
  }
}
.date-time-input {
  input {
    width: 110% !important;
  }
}
.card-email-modal {
  // box-shadow: 0px 0px 4px rgb(13 13 13 / 20%);
  border-bottom: 1px solid #e9eef3;
  // border-radius: 10px;
  background: #ffffff;
  position: relative;
  padding: 17px 10px 0;
  height: 100%;
  padding-bottom: 0;
}

.color-picker-wrapper {
  position: relative;
  width: 32px;
  height: 32px;

  .color-picker {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &::-webkit-color-swatch-wrapper {
      padding: 0;
    }

    &::-webkit-color-swatch {
      border: 1px solid #ddd;
      border-radius: 4px;
    }
  }
}
