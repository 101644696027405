
.field input{
    padding: 9.5px 4px !important;
    font-size: 15px !important;
    width: 100% !important;
    font-weight: 400 !important;
    background-color: #fff !important;
    height: 100% !important;
    border-radius: 4px !important;
    margin:0 !important;
}

.react-datepicker-wrapper {
    width: 100% !important;
    background: white !important;
  }

  .datePickerFilter {
    width: 100% !important;
    border-color: lightgray !important;
    border: 1px solid;
    border-radius: 4px;
    padding: 8px !important;
    background: white !important;
    margin-right:10px !important;
  }

  .datePickerFilter:focus {
    border-color: #4a9876 !important;
    border: 2px solid;
  }
  .locationFilter {
    width: 100% !important;
    border-color: lightgray !important;
    border: 1px solid;
    border-radius: 4px;
    background: white !important;
    
  }

  .locationFilter:focus {
    border-color: #4a9876 !important;
    border: 2px solid;
  }
